.root {
	composes: g-grid-container from global;
}

.smallCallout {
	display: flex;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-03);
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	padding-bottom: var(--hdsplus-spacing-09);

	@media (--medium-up) {
		flex-direction: row;
		padding-bottom: var(--hdsplus-spacing-12);
	}

	@media (--large) {
		padding-bottom: var(--hdsplus-spacing-08);
	}

}

.badge:global(.hds-badge) {
	color: var(--token-color-foreground-strong);
	background: var(--token-color-border-highlight);
	border: 1px solid var(--token-color-foreground-highlight-on-surface);
}

.textStack {
	display: grid;
	text-align: center;
	padding-bottom: var(--hdsplus-spacing-06);

	@media (--large) {
		padding-bottom: var(--hdsplus-spacing-10);
	}
}

.headline {
	font-weight: 600;
	padding-bottom: var(--hdsplus-spacing-03);
	font-family: var(--hdsplus-typography-display-expressive-500-font-family);
	font-size: var(--hdsplus-typography-display-expressive-500-font-size);
	line-height: var(--hdsplus-typography-display-expressive-500-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-500-letter-spacing);

	@media (--medium-up) {
		padding-bottom: var(--hdsplus-spacing-04);
		font-family: var(--hdsplus-typography-display-expressive-600-font-family);
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-800-font-family);
		font-size: var(--hdsplus-typography-display-expressive-800-font-size);
		line-height: var(--hdsplus-typography-display-expressive-800-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-800-letter-spacing);
	}
}

.description {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	letter-spacing: var(--hdsplus-typography-body-200-letter-spacing);

	@media (--medium-up) {
		padding-bottom: var(--hdsplus-spacing-04);
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-body-400-font-family);
		font-size: var(--hdsplus-typography-body-400-font-size);
		line-height: var(--hdsplus-typography-body-400-line-height);
		letter-spacing: var(--hdsplus-typography-body-400-letter-spacing);
	}
}

.ctas {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05) var(--hdsplus-spacing-06);
	margin-top: var(--hdsplus-spacing-07);
	justify-content: center;
	grid-column: 1 / -1;

	@media (--medium-up) {
		margin-top: var(--hdsplus-spacing-08);
	}

	@media (--large) {
		margin-top: var(--hdsplus-spacing-09);
	}

	/* This overrides the default dark colors for the HDS Button */
	& :global(.hds-button) {
		color: #3b3d45;
		background-color: #fafafa;
		border-color: #3b3d4566;
		box-shadow: 0 1px 1px 0 #656a760d, 0 2px 2px 0 #656a760d;
	
		&:hover {
			background-color: #fff;
			color: #3b3d45;
			cursor: pointer;
		}
	}
}


.icon {
	height: 100%;
	display: flex;
}

.images {
	composes: g-grid-container from global;
	
	@media (--small) {
		max-width: unset;
		padding: 0;
		margin: 0;
	}
}

.image {
	display: none;
	position: relative;
	
	& img {
		width: 100%;
		max-width: 100%;
		height: 100%;
	}
}

.mobile {
	display: flex;

	/*
		Specifically using 744px instead of 768px to treat the 6th gen iPad mini
		as a tablet
	*/
	@media (min-width: 744px) {
		display: none;
	}
}

.tablet {
	display: none;

	/*
		Specifically using 744px instead of 768px to treat the 6th gen iPad mini
		as a tablet
	*/
	@media (min-width: 744px) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--large) {
		display: flex;
	}
}
